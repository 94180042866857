import need_help_bg from '../../../img/landing/need_help_bg.png'
import need_help_title_icon from '../../../img/landing/need_help_title_icon.svg'
import { useState, useRef, useEffect } from 'react';
import { Pages } from '../../../utils/Utils';
import landing_pic_2  from '../../../img/landing/landing_pic_2.svg'
import autosize from 'autosize';
function NeedHelp(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const textRef = useRef(null)
    useEffect(() => {
        autosize(textRef.current)
    }, [])
    return(
        <div className="relative">
             
            <div className="need-help">
                {/* <img className="need-help_bg_image" src={need_help_bg}/> */}
                
                <div>
                    <div className="need-help_title_cont">
                        <div className="need-help_title">Need help? Contact us</div>
                        <img className="need-help_title_icon" src={need_help_title_icon}/>
                    </div>
                    
                    <InputRow placeholder="Your name" type="text" value={name} setValue={setName}/>
                    <InputRow placeholder="Your email address" type="email" value={email} setValue={setEmail}/>
                    {/* <InputRow placeholder="Describe your problem" type="text" value={text} setValue={setText}/> */}
                    
                    <div>
                        <textarea style={{paddingBottom : '0'}} ref={textRef} placeholder="Describe your problem" className="need-help_input"  value={text} onChange={(e) => setText(e.target.value)}/>
                        <div className="fill_navbar_line"/>
                    </div>
                </div>
                <button className="need-help_btn hover">Get in touch</button>
                <div className="need-help_privacy">You agree with <a href={Pages.privacy_policy} target="_blank" className="hover">Privacy Policy</a></div>
            
            </div>
            <img className="landing_pic_girl" src={landing_pic_2}/>
        </div>
    )
}
export default NeedHelp;


const InputRow = ({ placeholder, type, value, setValue }) => {
   
    return (
        <div>
            <input className="need-help_input" placeholder={placeholder} type={type} value={value} onChange={(e) => setValue(e.target.value)}/>
            <div className="fill_navbar_line"/>
        </div>
    );
}

