
import qs from 'querystring'
import { getCookie, setCookie } from './CookieHelper';
import moment from 'moment'
import React from 'react';

export function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? undefined : decodeURIComponent(sParameterName[1]);
        }
    }
};
export const declension = (count, one, two, five) => {
    var realCount = count;
  
    if (count > 100)
        count %= 100;
  
    if (count > 20)
        count %= 10;
  
    switch (count) {
        case 1:
            return one;
        case 2:
        case 3:
        case 4:
            return two;
        default:
            return five;
    }
}
export const Pages = {
	auth: "/auth",
	sign_in: "/auth/sign_in",
	sms_code: "/auth/sms_code",
	sign_up: "/auth/sign_up",
	cards: "/cards",
	privacy_policy: "https://drive.google.com/file/d/1NPmC7O18baCcBgZg8HYUYa1QcRnQfDmh/view?usp=sharing",
	cardholder_agreement: "https://drive.google.com/file/d/16k3zm8QGKdjJDsCnXJ1xKqaIYwb7qCnu/view?ts=642d85bc",
	terms_of_use: "https://drive.google.com/file/d/1iM7WfUnH3k-VxF_N_BIu4igw1NZwDQQm/view?ts=642d85c7",
	ios_app_url : "https://apps.apple.com/us/app/congratz-gift-cards/id6448018034",
	android_app_url : 'https://play.google.com/store/apps/details?id=io.congratz.android',
}

export const CookieConst = {
    token: "token",
    contact : 'contact',
    clear_contact : 'clear_contact',
}
export const CardStatus = {
    DRAFT: "DRAFT",
    NEW: "NEW",
    TRANSFERED: "TRANSFERED",
    NEW_NO_MONEY: "NEW_NO_MONEY",
    BLOCKED: "BLOCKED",
    ACTIVATED: "ACTIVATED",
   
}
export const AppState = {
    test: "test",
    dev : "dev"
}
export const ConstParams = {
    phoneLength : 17,
    appState : AppState.dev
}
export const getPhoneMaskPlaceholder = () => {
   return "+1 (000) 000-0000"
}
export const  getPhoneMask = () =>{
    return '+{1} (000) 000-0000'
}
export const getIsMobile = () => {
    return window.innerWidth <= 640
}
export const logOut = () => {
    setCookie(CookieConst.token, null)
    window.location.href = Pages.sign_in
}
export const openApp = () => {
    window.open(Pages.ios_app_url, '_blank');
}
export const openAndroidApp = () => {
    window.open(Pages.android_app_url, '_blank');
}
export const openAgreement = () => {
    window.open(Pages.cardholder_agreement, '_blank');
}
export const MyImage = React.memo(function Image({ src, className }) {
    return <img src={src} className={className} />;
});
